import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";

class NoLinkTabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
        className: PropTypes.string.isRequired,
        tabClassName: PropTypes.string.isRequired,
        tabActiveClassName: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.children[0].props.label,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: { children },
            state: { activeTab },
        } = this;

        return (
            <div className="no-link-tabs">
                <ol className="tab-box-list">
                    {children.map((child) => {
                        const { label } = child.props;
                        return (
                            <Tab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                className={this.props.tabClassName}
                                activeClassName={this.props.tabActiveClassName}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </ol>
                <div className="tab-content">
                    {children.map((child) => {
                        if (child.props.label !== activeTab) {
                            return undefined;
                        }
                        if (child.props.children !== undefined) {
                            return child.props.children;
                        }
                        return child;
                    })}
                </div>
            </div>
        );
    }
}

export default NoLinkTabs;
