import React from "react";

const Explainer = (props) => {
    return (
        <div style={{ ...props.style, ...{ padding: "0 0 4em 0" } }}>
            <h3>Getting Started</h3>
            <br />
            Bondsynth AI reads text in epubs, pdfs, text, and websites with a natural voice <br />
            <br />
            <div style={{ ...props.style, lineHeight: "130%" }}>
                <b>Ebook: </b>
                <br />
                Select a file under the 'ebook' tab and click synthesize
                <br />
                <b>PDF: </b>
                <br />
                Select a file under the 'pdf' tab and click synthesize
                <br />
                <b>Text: </b>
                <br />
                Enter free-form text under the 'text' tab and click synthesize
                <br />
                <b>Web: </b>
                <br />
                Enter a URL into the 'web' tab and click synthesize
                <br />
            </div>
            <br />
            <br />
            Generating audio takes a few moments to complete. <br />
            <br />
            If you submit a lot of text, save the download page's URL and check
            back later.
            <br />
        </div>
    );
};

export default Explainer;
