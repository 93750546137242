import React, { Component } from "react";
import PropTypes from "prop-types";
import NoLinkTabs from "../components/NoLinkTabs";
import FileUploadTab from "./file_upload_tab";
import SampleTab from "./sample_tab";
import TextTab from "./text_tab";
import WebTab from "./web_tab";

class SynthesisBox extends Component {
    static propTypes = {
        apiEndpoint: PropTypes.string.isRequired,
        ebookSynthesisLink: PropTypes.string.isRequired,
        sampleSynthesisLink: PropTypes.string.isRequired,
        textSynthesisLink: PropTypes.string.isRequired,
        webSynthesisLink: PropTypes.string.isRequired,
    };

    componentDidMount() { }

    componentDidUpdate() { }

    render() {
        const ebookFileTypes = ["epub"];
        const pdfFileTypes = ["pdf"];
        return (
            <div>
                <div className="box" label="box">
                    <NoLinkTabs
                        className="tab-box-list"
                        tabClassName="tab-box-list-item"
                        tabActiveClassName="tab-box-list-active"
                    >
                        <SampleTab
                            apiEndpoint={this.props.apiEndpoint}
                            label="Samples"
                            sampleSynthesisLink={this.props.sampleSynthesisLink}
                        />
                        <FileUploadTab
                            apiEndpoint={this.props.apiEndpoint}
                            apiMethodName="/synthesisEbook"
                            fileTypes={ebookFileTypes}
                            label="Ebook"
                            ebookSynthesisLink={this.props.ebookSynthesisLink}
                        />
                        <FileUploadTab
                            apiEndpoint={this.props.apiEndpoint}
                            apiMethodName="/synthesisPdf"
                            fileTypes={pdfFileTypes}
                            label="Pdf"
                            ebookSynthesisLink={this.props.ebookSynthesisLink}
                        />
                        <TextTab
                            apiEndpoint={this.props.apiEndpoint}
                            label="Text"
                            textSynthesisLink={this.props.webSynthesisLink}
                        />
                        <WebTab
                            apiEndpoint={this.props.apiEndpoint}
                            label="Web"
                            webSynthesisLink={this.props.webSynthesisLink}
                        />
                    </NoLinkTabs>
                </div>
            </div>
        );
    }
}

export default SynthesisBox;
